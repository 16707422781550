/* eslint-disable indent */
/* eslint-disable unicorn/consistent-function-scoping */
import { ParsedUrlQuery } from "node:querystring";

import {
  CardPromotionRepostResponseDto,
  DeckDetailResponseDto,
} from "@earthtoday/contract";
import axios from "axios";
import {
  action,
  computed,
  flow,
  IObservableArray,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import { ReactNode } from "react";
import { toFlowGeneratorFunction } from "to-flow-generator-function";

import { PromotionCount } from "../../__generated__/ts-gql/@schema";
import etCommunity from "../assets/img/et-community.png";
import etM2 from "../assets/img/et-m2.png";
import sdgColors from "../assets/img/sdg-colors.png";
import {
  ErrorMessage,
  TheMessageStore,
} from "../components/TheMessage/TheMessageStore";
import { PaymentProvider } from "../shared/apis/PaymentApi";
import { UnsubscribeFn } from "../shared/apis/UnsubscribeFn";
import { translateAPIError } from "../shared/helpers/translateApiError";
import { first, groupBy } from "../shared/lodash";
import { Card, CardRegular, CardType, Vote } from "../shared/models/Card";
import { IConsumer } from "../shared/models/Consumer";
import { User } from "../shared/models/User";
import { CardActionModel } from "./CardActionModel/CardActionModel";
import { CardDeckHeaderModel } from "./CardDeckHeaderModel";
import { CardDeckRepostModel } from "./CardDeckRepostModel";
import { CardHomeModel } from "./CardHomeModel";
import { CardInfoModel } from "./CardInfoModel/CardInfoModel";
import { CardItemEditablePresenter } from "./CardItemEditablePresenter/CardItemEditablePresenter";
import { CardItemEditableRepostPresenter } from "./CardItemEditablePresenter/CardItemEditableRepostPresenter";
import { CardProfileRepostModel } from "./CardProfileRepostModel";
import { CardItemPromotionLocation } from "./CardPromotionModel/CardPromotionBaseModel";
import { CardPromotionRepostModel } from "./CardPromotionModel/CardPromotionRepostModel";
import { LeaderboardsRange } from "./DeckDetailStore/DeckDetailStore";
import { IFollowingRequest } from "./FollowingStore";
import { HomeHeaderCardStore } from "./HomeHeaderCardStore";
import {
  LeaderBoardRangeModel,
  LeaderBoardRangeType,
  UonRangeParam,
} from "./LeaderBoardRangeModel";
import { ModalStore } from "./ModalStore";
import { RootStore } from "./rootStore";
import { UserModel } from "./UserModel";

export type LeaderBoardTab = "recent" | "top";
export type HomeCard = Card | CardPromotionRepostResponseDto;

export interface CarouselItem {
  title: string;
  text: string;
  img: string;
  linkTitle: ReactNode;
  linkText: string;
  link: string;
}
export interface HomeData {
  page: "home";
  protectedCount: number;
  // topLeaderboard: IConsumer[];
  // recentLeaderboard: IConsumer[];
  fetchCardError: ErrorMessage;
  cards: HomeCard[];
  earthTodayProfile: User | null;
  fetchEarthTodayProfileError: ErrorMessage;
  deckDetail: DeckDetailResponseDto | null;
  fetchDeckDetailError: ErrorMessage;
  isPageLoading: boolean;
}

const carouselStaticData: CarouselItem[] = [
  {
    title: "Nature protection at scale ",
    text: "EarthToday is on a mission to increase awareness​ on the state of the planet and accelerate its protection. Meter by meter. ",
    img: etM2,
    linkTitle: (
      <>
        Protect a m<sup>2</sup>
      </>
    ),
    linkText: "Protect 1 m<sup>2</sup> for €1.20",
    link: "https://join.earthtoday.com/",
  },
  {
    title: "UN Sustainable Development Goals ",
    text: "17 goals for a better world by 2030, that have the power to end poverty, fight inequality and address the urgency of climate change. ",
    img: sdgColors,
    linkTitle: <>Explore all 17</>,
    linkText: "To inform, inspire, educate and entertain ",
    link: "/play",
  },
  {
    title: "Community of Changemakers ",
    text: "We envision a future society where we take care of each other and the planet we live on. Join EarthToday’s conscious crowd. ",
    img: etCommunity,
    linkTitle: <div>Sign Up</div>,
    linkText: "Start following, starring and more. ",
    link: "#",
  },
];
export type HomeCardItem =
  | CardHomeModel
  | CardDeckRepostModel
  | CardProfileRepostModel
  | CardActionModel
  | CardInfoModel
  | CardPromotionRepostModel
  | CardItemEditablePresenter
  | CardItemEditableRepostPresenter;

export class HomeStore {
  carouselStaticData: CarouselItem[] = carouselStaticData;
  theMessageStore: TheMessageStore;
  @observable chosenDeckId: string = "";
  @observable isPageLoading: boolean = true;
  @observable cards: IObservableArray<HomeCardItem> = observable<HomeCardItem>(
    [],
  );
  @observable chosenCardId: string = "";

  @observable currentLeaderBoardTab: LeaderBoardTab = "recent";
  @observable currentCarousel: number = 0;
  @observable shareId: string = "";
  @observable starId: string = "";
  @observable earthTodayProfile: UserModel | null = null;
  @observable deckDetail: CardDeckHeaderModel | null = null;
  @observable deckId: string = "";
  @observable showOverlay: boolean = true;
  @observable isDonated: boolean = false;
  @observable fetchCardError: ErrorMessage = null;
  @observable fetchProtectedCountError: ErrorMessage = null;
  @observable fetchLeaderboardRecentError: ErrorMessage = null;
  @observable fetchLeaderboardTopError: ErrorMessage = null;
  @observable fetchEarthTodayProfileError: ErrorMessage = null;
  @observable fetchDeckDetailError: ErrorMessage = null;
  @observable fetchCardsMetadataError: ErrorMessage = null;
  @observable isLeaderboardRangeLoading: boolean = true;

  initLoggedinState: boolean = !!this.rootStore.userSessionStore.user;
  @observable leaderboardsRange: LeaderboardsRange = {
    [UonRangeParam.TEN_TO_FOURTY_NINE]: new LeaderBoardRangeModel(
      this.rootStore.modalStore,
      this.rootStore.featureFlaggingStore.flags,
      this.rootStore.donateStore,
      LeaderBoardRangeType.TEN_TO_FOURTY_NINE,
    ),
    [UonRangeParam.FIFTY_TO_NINETY_NINE]: new LeaderBoardRangeModel(
      this.rootStore.modalStore,
      this.rootStore.featureFlaggingStore.flags,
      this.rootStore.donateStore,
      LeaderBoardRangeType.FIFTY_TO_NINETY_NINE,
    ),
    [UonRangeParam.FIVE_TO_NINE]: new LeaderBoardRangeModel(
      this.rootStore.modalStore,
      this.rootStore.featureFlaggingStore.flags,
      this.rootStore.donateStore,
      LeaderBoardRangeType.FIVE_TO_NINE,
    ),
    [UonRangeParam.ONE_TO_FOUR]: new LeaderBoardRangeModel(
      this.rootStore.modalStore,
      this.rootStore.featureFlaggingStore.flags,
      this.rootStore.donateStore,
      LeaderBoardRangeType.ONE_TO_FOUR,
    ),
  };

  constructor(public rootStore: RootStore) {
    this.homeHeaderCardDriver = new HomeHeaderCardStore(
      rootStore.donateStore,
      rootStore.modalStore,
      rootStore.featureFlaggingStore,
      rootStore.userSessionStore,
    );
    makeObservable(this);
    this.theMessageStore = rootStore.theMessageStore;
  }
  homeHeaderCardDriver: HomeHeaderCardStore;

  get getCarouselStaticData(): CarouselItem[] {
    return this.rootStore.userSessionStore.user
      ? this.carouselStaticData.slice(0, -1)
      : this.carouselStaticData;
  }

  public dehydrate(): HomeData {
    return {
      protectedCount: this.homeHeaderCardDriver.protectedCount || 0,
      // topLeaderboard: this.topLeaderboard,
      // recentLeaderboard: toJS(this.recentLeaderboard),
      fetchCardError: this.fetchCardError,
      cards: this.cards.map((card) => card.toJSON()) || [],
      earthTodayProfile: this.earthTodayProfile?.toJSON() || null,
      fetchEarthTodayProfileError: this.fetchEarthTodayProfileError,
      deckDetail: this.deckDetail ? this.deckDetail.toJSON() : null,
      fetchDeckDetailError: this.fetchDeckDetailError,
      page: "home",
      isPageLoading: this.isPageLoading,
    };
  }

  @action.bound public hydrate(data: HomeData): void {
    this.cards.replace(
      data.cards.map((card) => this.parseCardResponseToCardItem(card)),
    );

    this.fetchCardError = data.fetchCardError;
    this.earthTodayProfile = data.earthTodayProfile
      ? new UserModel(
          data.earthTodayProfile,
          this.rootStore.userSessionStore,
          this.rootStore.tokenStore,
        )
      : null;
    this.deckDetail =
      data.deckDetail &&
      new CardDeckHeaderModel(
        this.rootStore.modalStore,
        this.rootStore.theMessageStore,
        this.rootStore.userSessionStore,
        this.rootStore.modalDeleteDeckStore,
        this.rootStore.autoplayDeckStore,
        this,
        data.deckDetail,
        this.rootStore.deviceStore,
        this.rootStore.featureFlaggingStore,
        this.rootStore.deckDetailApi,
      );
    this.fetchDeckDetailError = data.fetchDeckDetailError;
    this.fetchEarthTodayProfileError = data.fetchEarthTodayProfileError;
    this.isPageLoading = data.isPageLoading;
    this.homeHeaderCardDriver.protectedCount = data.protectedCount;
  }

  @action.bound fetchPageDataServer = flow(function* fetchPageDataServer(
    this: HomeStore,
  ) {
    yield this.fetchCards();
  });
  @action.bound fetchPageDataBrowser = flow(function* fetchPageDataBrowser(
    this: HomeStore,
  ) {
    this.isPageLoading = true;
    yield Promise.all([
      this.fetchProtectedCount(),
      this.fetchLeaderboardsCustomRange(),
      this.fetchLeaderboardRecent(),
      this.fetchPromotionCounts(),
    ]);
    this.isPageLoading = false;
  });

  @action.bound fetchLeaderboardRecent = flow(function* fetchLeaderboardRecent(
    this: HomeStore,
  ) {
    const protectors =
      yield this.rootStore.protectPageApi.fetchLeaderboardRecent();

    this.homeHeaderCardDriver.lastProtector = first(protectors) || null; // already sorted in BE
  });

  parseCardResponseToCardItem(card: HomeCard): HomeCardItem {
    if (card.contentType === CardType.PROMOTION_REPOST) {
      return new CardPromotionRepostModel(
        card,
        this.rootStore.userSessionStore,
        this.rootStore.donateStore,
        this.rootStore.modalStore,
        null,
        {
          onDeleteCard: this.onDeleteCard,
          onDraftingCardPromotionPublished: () => {},
          getCardOrder: () => 0,
          onCardOrderInputChanged: () => {},
        },
        this.rootStore.modalDeleteCardStore,
        this.rootStore.featureFlaggingStore,
        this.rootStore.createCardApi,
        this.rootStore.profileApi,
        this.rootStore.profileStore,
        this.rootStore.theMessageStore,
        CardItemPromotionLocation.HOME,
      );
    }
    if (card.contentType === CardType.EDITABLE) {
      return new CardItemEditablePresenter(
        card,
        this.rootStore.modalStore,
        this.rootStore.deckDetailApi,
        null,
        this.rootStore.theMessageStore,
        this.rootStore.userSessionStore,
        this.rootStore.modalDeleteCardStore,
        this.rootStore.featureFlaggingStore,
        this.rootStore.deckDetailStore,
      );
    }
    if (card.contentType === CardType.EDITABLE_REPOST) {
      return new CardItemEditableRepostPresenter(
        card,
        this.rootStore.modalStore,
        this.rootStore.deckDetailApi,
        null,
        this.rootStore.theMessageStore,
        this.rootStore.userSessionStore,
        this.rootStore.modalDeleteCardStore,
        this.rootStore.featureFlaggingStore,
        this.rootStore.deckDetailStore,
      );
    }
    if (card.contentType === CardType.ACTION) {
      return new CardActionModel(
        card,
        this.rootStore.modalStore,
        this.rootStore.userSessionStore,
        this.rootStore.deckDetailApi,
        null,
        this.theMessageStore,
        this.rootStore.modalDeleteCardStore,
        this.rootStore.featureFlaggingStore,
        null,
      );
    }
    if (card.contentType === CardType.INFO) {
      return new CardInfoModel(
        card,
        this.rootStore.modalStore,
        this.rootStore.deckDetailApi,
        null,
        this.rootStore.theMessageStore,
        this.rootStore.userSessionStore,
        this.rootStore.modalDeleteCardStore,
        this.rootStore.featureFlaggingStore,
        this.rootStore.deckDetailStore,
      );
    }
    if (card.contentType === CardType.PROFILE_REPOST && !!card.profileRepost) {
      return new CardProfileRepostModel(
        card,
        this.rootStore.modalStore,
        this.rootStore.theMessageStore,
        this.rootStore.modalDeleteCardStore,
        this.rootStore.userSessionStore,
        this.rootStore.tokenStore,
        null,
        this.rootStore.featureFlaggingStore,
        this.rootStore.deviceStore,
        this.rootStore.deckDetailApi,
      );
    }

    if (card.contentType === CardType.DECK_REPOST && !!card.deckRepost) {
      return new CardDeckRepostModel(
        card,
        this.rootStore.modalStore,
        this.rootStore.theMessageStore,
        this.rootStore.userSessionStore,
        this.rootStore.modalDeleteCardStore,
        this.rootStore.autoplayDeckStore,
        null,
        this.rootStore.featureFlaggingStore,
        this.rootStore.deviceStore,
        this.rootStore.deckDetailApi,
      );
    }

    if (card.contentType === CardType.AUTOPLAY && !!card.autoplaySourceCard) {
      if (card.autoplaySourceCard.contentType === CardType.PROFILE_REPOST) {
        return new CardProfileRepostModel(
          card.autoplaySourceCard as CardRegular,
          this.rootStore.modalStore,
          this.rootStore.theMessageStore,
          this.rootStore.modalDeleteCardStore,
          this.rootStore.userSessionStore,
          this.rootStore.tokenStore,
          null,
          this.rootStore.featureFlaggingStore,
          this.rootStore.deviceStore,
          this.rootStore.deckDetailApi,
        );
      }
      return new CardHomeModel(
        {
          ...card.autoplaySourceCard,
          cardAutoplayId: card.id,
          size: card.size,
          layoutType: card.layoutType,
        } as CardRegular,
        this,
        this.rootStore.modalStore,
        this.rootStore.modalDeleteCardStore,
        this.rootStore.theMessageStore,
        this.rootStore.userSessionStore,
        this.rootStore.featureFlaggingStore,
        this.rootStore.deckDetailApi,
        this.rootStore.deviceStore,
      );
    }

    if (
      card.contentType === CardType.AUTOPLAY_FOLLOWING_PERSONALIZATION &&
      !!card.autoplayFollowingPersonalization
    ) {
      return new CardHomeModel(
        {
          ...card.autoplayFollowingPersonalization,
        } as CardRegular,
        this,
        this.rootStore.modalStore,
        this.rootStore.modalDeleteCardStore,
        this.rootStore.theMessageStore,
        this.rootStore.userSessionStore,
        this.rootStore.featureFlaggingStore,
        this.rootStore.deckDetailApi,
        this.rootStore.deviceStore,
      );
    }

    return new CardHomeModel(
      card,
      this,
      this.rootStore.modalStore,
      this.rootStore.modalDeleteCardStore,
      this.rootStore.theMessageStore,
      this.rootStore.userSessionStore,
      this.rootStore.featureFlaggingStore,
      this.rootStore.deckDetailApi,
      this.rootStore.deviceStore,
    );
  }

  @action.bound fetchCards = flow(function* fetchCards(this: HomeStore) {
    try {
      const res = yield this.rootStore.homeApi.fetchCards();
      this.cards.replace(
        res.cards.map((card) => this.parseCardResponseToCardItem(card)),
      );
      this.earthTodayProfile = new UserModel(
        res.profile,
        this.rootStore.userSessionStore,
        this.rootStore.tokenStore,
      );
      this.deckDetail = new CardDeckHeaderModel(
        this.rootStore.modalStore,
        this.rootStore.theMessageStore,
        this.rootStore.userSessionStore,
        this.rootStore.modalDeleteDeckStore,
        this.rootStore.autoplayDeckStore,
        this,
        { ...res.deck, cardsCount: 52 },
        this.rootStore.deviceStore,
        this.rootStore.featureFlaggingStore,
        this.rootStore.deckDetailApi,
      );

      this.rootStore.metaStore.updateMetaTags(res.metaTags || {});

      this.fetchCardError = null;
      this.fetchEarthTodayProfileError = null;
      this.fetchDeckDetailError = null;
    } catch (error) {
      this.fetchCardError = translateAPIError(error);
      this.fetchEarthTodayProfileError = translateAPIError(error);
      this.fetchDeckDetailError = translateAPIError(error);
    }
  });

  @action.bound fetchUserCardsMetadata = flow(function* fetchUserCardsMetadata(
    this: HomeStore,
  ) {
    if (!this.rootStore.userSessionStore.user) {
      for (const card of this.cards) {
        if ("metadata" in card && card.metadata.starred)
          card.updateStarCard(false);
      }
      return;
    }

    const cardIds: string[] = [];

    for (const card of this.cards) {
      if (card.contentType !== CardType.REMOVEDCONTENT && "newestId" in card) {
        cardIds.push(card.newestId);
      }
    }

    if (cardIds.length === 0) {
      return;
    }
    try {
      const res = yield this.rootStore.userSessionApi.fetchUserCardsMetadata(
        cardIds,
      );
      if (res.length === 0) {
        return;
      }
      const starredCards: string[] = [];
      for (const card of res) {
        if (card.starred) starredCards.push(card.id);
      }

      for (const card of this.cards) {
        if ("newestId" in card && starredCards.includes(card.newestId)) {
          card.updateStarCard(true);
        }
      }
    } catch (error) {
      this.fetchCardsMetadataError = translateAPIError(error);
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "Error",
        content: translateAPIError(error),
      });
    }
  });

  @action.bound fetchProtectedCount = flow(function* fetchProtectedCount(
    this: HomeStore,
  ) {
    try {
      const res = yield this.rootStore.homeApi.fetchProtectedCount();
      this.homeHeaderCardDriver.protectedCount = res;
      if (this.fetchProtectedCountError) {
        this.fetchProtectedCountError = null;
      }
    } catch (error) {
      this.fetchProtectedCountError = translateAPIError(error);
    }
  });

  @action.bound
  updateShowOverlay(b: boolean): void {
    this.showOverlay = b;
  }

  @action.bound
  closeOverlay(): void {
    this.showOverlay = false;
    this.isDonated = false;

    this.rootStore.userSessionStore.setUserCloseOverlay();
  }

  @action.bound toggleFollowing = flow(function* toggleFollowing(
    this: HomeStore,
    data: IFollowingRequest,
  ) {
    const { followingApi: api } = this.rootStore;
    try {
      this.optimisticFollowing(data.following);
      const res = yield api.toggleFollowingDecks(data);
      this.optimisticFollowing(res.following, res.count);
      return true;
    } catch (error) {
      this.optimisticFollowing(!data.following);
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "Error",
        content: translateAPIError(error),
      });
    }
  });

  @action chosenDeckIdUpdate = (id: string): void => {
    this.chosenDeckId = id;
  };

  @action.bound updateStarCard = flow(function* updateStarCard(
    this: HomeStore,
    id: string,
    starred: boolean,
  ) {
    try {
      this.optimisticStarCard(id, starred, this.cards);
      yield this.rootStore.cardApi.starCard(id, starred);
    } catch (error) {
      this.optimisticStarCard(id, starred, this.cards);
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "Error",
        content: translateAPIError(error),
      });
    }
  });

  @action.bound onDeleteCard = flow(function* onDeleteCard(
    this: HomeStore,
    cardId: string,
  ) {
    const { profileApi: api } = this.rootStore;
    try {
      yield api.deleteCard(cardId);
      this.optimisticDelete(cardId);
      this.theMessageStore.showMessage({
        typeMessage: "Close",
        title: "Your Card has been deleted",
        content: "Your Card has been deleted just as you wanted",
      });
    } catch (error) {
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "Error",
        content: translateAPIError(error),
      });
    }
  });

  @action setModalFunc = (modalStore: ModalStore): void => {
    this.carouselStaticData[2].linkTitle = (
      <div onClick={() => modalStore.openModal("signupForm")}>Sign Up</div>
    );
  };

  @action onChosenCardIdUpdate = (s: string): void => {
    this.chosenCardId = s;
  };

  @action currenLeaderBoardTabUpdate = (
    leaderBoardTab: LeaderBoardTab,
  ): void => {
    this.currentLeaderBoardTab = leaderBoardTab;
  };

  @action currentCarouselUpdate = (n: number): void => {
    this.currentCarousel = n;
  };

  @action toggleShare = (id: string): void => {
    this.shareId = id;
  };

  @action updateDeckId = (id: string): void => {
    this.deckId = id;
  };

  @action setStarId = (id: string): void => {
    this.starId = id;
  };

  @computed get cardItems(): HomeCardItem[] {
    return this.cards.filter((card) => !card.isFeaturedDeck);
  }

  @computed get cardItemFeatured(): HomeCardItem | null {
    if (this.cards.length === 0) {
      return null;
    }
    return this.cards[0].isFeaturedDeck ? this.cards[0] : null;
  }

  @computed get fetchPageError(): boolean {
    return (
      !!this.fetchCardError &&
      !!this.fetchProtectedCountError &&
      !!this.fetchEarthTodayProfileError &&
      !!this.fetchDeckDetailError
    );
  }

  @computed get deckPath(): string {
    return this.earthTodayProfile?.featuredCard?.deck?.path?.join("/") || "";
  }

  @computed get isLoggedIn(): boolean {
    return !!this.rootStore.userSessionStore.user;
  }

  @action
  updateIsDonated(query: ParsedUrlQuery): void {
    this.isDonated =
      query.context === "payments" &&
      query.provider === PaymentProvider.MOLLIE &&
      query.id
        ? true
        : false;
  }

  @computed get isShowAcceptCookies(): boolean {
    return !this.rootStore.userSessionStore.hasAcceptedSocialCookies;
  }

  @action.bound editDeck = flow(function* editDeck(this: HomeStore) {});

  @action.bound
  optimisticDelete(this: HomeStore, id: string): void {
    const foundIndex = this.cards.findIndex(
      (card) => "newestId" in card && card.newestId === id,
    );
    if (foundIndex !== -1) {
      this.cards.splice(foundIndex, 1);
    }
  }

  @action optimisticStarCard = (
    id: string,
    starred: boolean,
    cards: IObservableArray<HomeCardItem>,
  ): void => {
    const foundIndex = cards.findIndex(
      (card) => "newestId" in card && card.newestId === id,
    );
    const card = cards[foundIndex];

    if (
      !card ||
      card.contentType === CardType.ACTION ||
      card.contentType === CardType.INFO ||
      card.contentType === CardType.PROMOTION_REPOST
    ) {
      return;
    }

    if (foundIndex !== -1) {
      card.updateStarCard(starred);
    }
  };

  @action optimisticVoteCard = (
    id: string,
    data: IObservableArray<HomeCardItem>,
    state: Vote,
  ): void => {
    const foundIndex = data.findIndex(
      (card) => "newestId" in card && card.newestId === id,
    );
    if (foundIndex === -1) {
      return;
    }

    const card = data[foundIndex]?.card;

    if (
      !card ||
      card.contentType === CardType.ACTION ||
      card.contentType === CardType.INFO ||
      card.contentType === CardType.EDITABLE ||
      card.contentType === CardType.EDITABLE_REPOST ||
      card.contentType === CardType.PROMOTION_REPOST
    ) {
      return;
    }

    if (!card.metaTags) {
      return;
    }

    const metaTags = card.metaTags || {};

    Object.assign(metaTags, {
      vote: state,
    });
  };

  @action optimisticFollowing = (following: boolean, count?: number): void => {
    if (!this.deckDetail) return;
    const newCounts =
      count === undefined
        ? following
          ? this.deckDetail.followingCounts + 1
          : this.deckDetail.followingCounts - 1
        : count;

    this.deckDetail.updateFollowCounts(newCounts, following);
  };

  unsubscribeFns: UnsubscribeFn[] = [];

  @action.bound subscribe = (): void => {
    this.unsubscribeFns.push(
      this.rootStore.homeApi.subscribeUonCount((error, count) => {
        if (error) {
          this.fetchProtectedCountError = translateAPIError(error);
          this.theMessageStore.showMessage({
            typeMessage: "Error",
            title: "Error",
            content: translateAPIError(error),
          });
          return;
        }

        runInAction(() => {
          this.homeHeaderCardDriver.protectedCount = count;
          this.fetchProtectedCountError = null;
        });
      }),

      this.rootStore.protectPageApi.subscribeGlobalLeaderboard(
        (error, globalLeaderboardUpdated: IConsumer[] | null) => {
          if (error) {
            this.fetchLeaderboardRecentError = translateAPIError(error);
            this.theMessageStore.showMessage({
              typeMessage: "Error",
              title: "Error",
              content: translateAPIError(error),
            });
            return;
          }

          runInAction(() => {
            if (globalLeaderboardUpdated) {
              const recentDonationRanges = groupBy(
                globalLeaderboardUpdated,
                (item) => {
                  if (item.count < LeaderBoardRangeType.FIVE_TO_NINE) {
                    return UonRangeParam.ONE_TO_FOUR;
                  }
                  if (item.count < LeaderBoardRangeType.TEN_TO_FOURTY_NINE) {
                    return UonRangeParam.FIVE_TO_NINE;
                  }
                  if (item.count < LeaderBoardRangeType.FIFTY_TO_NINETY_NINE) {
                    return UonRangeParam.TEN_TO_FOURTY_NINE;
                  }
                  if (item.count < 100) {
                    return UonRangeParam.FIFTY_TO_NINETY_NINE;
                  }
                },
              );

              for (const [uonRange, items] of Object.entries(
                recentDonationRanges,
              )) {
                if (
                  uonRange &&
                  Object.values(UonRangeParam).includes(
                    uonRange as UonRangeParam,
                  )
                ) {
                  this.leaderboardsRange[uonRange].appendNewItems(items);
                }
              }
            }
            this.fetchLeaderboardRecentError = null;
          });
        },
      ),
    );
  };

  @action.bound unsubscribe = (): void => {
    for (const unsubscribe of this.unsubscribeFns) unsubscribe();
  };
  @action.bound fetchLeaderboardsCustomRange = flow(function* (
    this: HomeStore,
  ) {
    if (!this.rootStore.featureFlaggingStore.flags.enableNewHomePage) return;
    try {
      const res = yield Promise.all([
        this.rootStore.homeApi.fetchLeaderboardsCustomRange(
          UonRangeParam.ONE_TO_FOUR,
        ),
        this.rootStore.homeApi.fetchLeaderboardsCustomRange(
          UonRangeParam.FIVE_TO_NINE,
        ),
        this.rootStore.homeApi.fetchLeaderboardsCustomRange(
          UonRangeParam.TEN_TO_FOURTY_NINE,
        ),
        this.rootStore.homeApi.fetchLeaderboardsCustomRange(
          UonRangeParam.FIFTY_TO_NINETY_NINE,
        ),
      ]);

      this.leaderboardsRange[UonRangeParam.ONE_TO_FOUR].replaceData(res[0]);
      this.leaderboardsRange[UonRangeParam.FIVE_TO_NINE].replaceData(res[1]);
      this.leaderboardsRange[UonRangeParam.TEN_TO_FOURTY_NINE].replaceData(
        res[2],
      );
      this.leaderboardsRange[UonRangeParam.FIFTY_TO_NINETY_NINE].replaceData(
        res[3],
      );
    } catch (error) {
      if (axios.isCancel(error)) {
        return;
      }
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "Error",
        content: translateAPIError(error),
      });
    }
  });

  @computed get CardInFirstPositionId(): string {
    if (
      !this.rootStore.featureFlaggingStore.flags
        .enableFirstContentCardInHomePage
    ) {
      return "";
    }
    return this.cardItems[0].id;
  }

  @action.bound fetchPromotionCounts = flow(function* fetchPromotionCounts(
    this: HomeStore,
  ) {
    const promotions: CardPromotionRepostModel[] = this.cards.filter(
      (card) => card.contentType === CardType.PROMOTION_REPOST,
    ) as CardPromotionRepostModel[];

    if (promotions.length === 0) return;

    const promotionIds = promotions.map((promo) => promo.promotionId);

    const result = yield* toFlowGeneratorFunction(
      this.rootStore.profileApi.fetchPromotionCounts,
    )(promotionIds);
    const promotionCounts: Record<string, number> = {};

    for (const promo of result.data) {
      promotionCounts[promo.id] = promo.count;
    }

    for (const promo of promotions) {
      const count = promotionCounts[promo.promotionId];
      if (!count) {
        continue;
      }

      // update counter
      promo.updateCounter(count);

      // subscribe counter
      this.unsubscribeFns.push(
        this.rootStore.profileApi.subscribePromotionCount(
          promo.promotionId,
          (data: Omit<PromotionCount, "__typename">) => {
            runInAction(() => {
              if (!data.count || data.id !== promo.promotionId) {
                return;
              }
              promo.updateCounter(data.count);
            });
          },
        ),
      );
    }
  });

  @action.bound onOpenLoginModal(): void {
    this.rootStore.theNavbarStore.onOpenLoginModal();
  }
}
