import { observer } from "mobx-react-lite";

import { CardType } from "../../shared/models/Card";
import { HomeCardItem } from "../../stores/HomeStore";
import { ModalType } from "../../stores/ModalStore";
import { RendererArgs } from "../Grid/Grid";
import { CardDetailContextMenu } from "./CardDetailContextMenu";

interface IProps {
  card: HomeCardItem;
  rendererArgs: RendererArgs;
  openModal: (modalTypes: ModalType) => void;
}
export const LayoutCardDetailContextMenu = observer(
  function LayoutCardDetailContextMenu(props: IProps) {
    const { card, rendererArgs, openModal } = props;

    return card.contentType === CardType.PROMOTION_REPOST ? (
      <></>
    ) : (
      <CardDetailContextMenu
        driver={
          card.contentType === CardType.ACTION ||
          card.contentType === CardType.INFO ||
          card.contentType === CardType.EDITABLE
            ? card.cardContextMenuDriver
            : card
        }
        layoutDriver={rendererArgs}
        location="home"
        openModal={(modalTypes: ModalType) => openModal(modalTypes)}
      />
    );
  },
);
